import { useLocation } from "@remix-run/react";
import { load, trackPageview } from "fathom-client";
import { useEffect } from "react";
import { environmentVariable } from "~/environmentVariable";

export const Fathom = () => {
  const location = useLocation();

  useEffect(() => {
    const siteId = environmentVariable("PUBLIC_FATHOM_SITE_ID");
    const domains = environmentVariable("PUBLIC_FATHOM_DOMAINS")?.split(",");

    if (siteId && siteId.length > 0) {
      load(siteId, {
        includedDomains: domains || ["localhost"],
      });
    }
  }, []);

  useEffect(() => {
    trackPageview();
  }, [location.pathname, location.search]);

  return null;
};
